import {
  Grid,
  Card,
  CardActionArea,
  CardActionAreaClassKey,
  CardContent,
  CardMedia,
  Typography,
  TableRow,
  TableCell,
  Table,
  Box,
  Button,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import React from "react";
import HorizontalBarChart from "../../views/charts/HorizontalBarChart";
import { makeStyles } from "@material-ui/styles";
import ImageOverlayCard from "./ImageOverlayCard";

const useStyles = makeStyles(() => ({
  cardRoot: {
    minWidth: "25%",
  },
  cardMedia: {
    height: 300,
    objectFit: "contain",
    display: "block",
    margin: "0 auto",
    "&:hover": {
      transform: "scale(1.5)",
    },
  },
  tableHead: {
    fontWeight: 600,
  },
  greyBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    boxShadow: " rgba(0, 0, 0, 0.24) 0px 3px 8px",
    backgroundColor: "#f5f5f5",
    height: "100%",
    borderRadius: "12px",
    padding: "30px",
  },
}));

const TestCampaigns = ({ state }) => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <>
      <Box sx={{ marginBottom: "15px", marginTop: "-20px" }}>
        <Button
          sx={{ padding: "3px 20px", minWidth: "80px" }}
          variant="contained"
          color="secondary"
          size="small"
          onClick={() => history.push("/creative-output")}
        >
          Back
        </Button>
      </Box>
      <Grid container spacing={4}>
        <Grid item xs={8}>
          <HorizontalBarChart
            title={"Estimated Engagement Rate"}
            chartHeight={310}
            refId={"bar-chart"}
            data={state?.plotData}
            x_column={"title"}
            y_column={"theta"}
          />
        </Grid>
        <Grid
          item
          xs={4}
          display="flex"
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Box className={classes.greyBox}>
            <Typography
              gutterBottom
              variant="h4"
              textAlign={"center"}
              sx={{ fontWeight: 500, lineHeight: "25px", mb: 2 }}
            >
              Savings compared to Default A/B Testing in the platform
            </Typography>
            <Typography
              gutterBottom
              variant="h1"
              color={"primary"}
              component="h1"
              textAlign={"center"}
            >
              {state?.amountSaved}$
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={4}>
            {state?.plotData?.map((each, index) => (
              <Grid key={index} item xs={6}>
                <ImageOverlayCard obj={each} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default TestCampaigns;
