import React, { useState, useEffect } from "react";
import { Route, useLocation, Redirect, Switch } from "react-router-dom";
import ImageGeneration from "./ImageGeneration";
import Summary from "./Summary";
import {
  Container,
  AppBar,
  Toolbar,
  CssBaseline,
  Typography,
  TextField,
  Button,
  Grid,
  Autocomplete,
} from "@material-ui/core";
import axios from "axios";
import Header from "../layouts/UMGLayout/Header";

const InsuranceApp = () => {
  const location = useLocation();
  const [state, setState] = useState({
    currentCampaign: null,
    optionsData: {},
    campaignList: [],
    isLoading: false,
    selectedImage: [],
    selectedImageObj: {},
    plotData: [],
    amountSaved: "",
    showPlot: false,
  });

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      isLoading: true,
    }));
    axios
      .get("/api/v1/get-audience-and-platform/")
      .then((res) => {
        setState((prev) => ({
          ...prev,
          optionsData: res.data,
          isLoading: false,
        }));
      })
      .catch((e) => {
        setState((prev) => ({
          ...prev,
          isLoading: false,
        }));
      });
  }, []);

  const handleAddCampaign = () => {
    const campaignList = [...state.campaignList];

    campaignList.push({
      theme: "Restaurant",
      location: "",
      target_demography: "",
      call_to_action: "",
      platfrom: "",
      call_to_customers: "",
      imageGenerationData: [],
      block1: false,
    });

    setState((prev) => {
      if (state.currentCampaign === null) {
        return {
          ...prev,
          currentCampaign: 0,
          campaignList,
        };
      } else {
        return {
          ...prev,
          currentCampaign: state.campaignList + 1,
          campaignList,
          showPlot: false,
        };
      }
    });
  };

  const handleInputChange = (id, value, index) => {
    console.log(value);
    const campaignList = [...state.campaignList];
    let obj = campaignList[index];
    obj = {
      ...obj,
      [id]: value,
    };

    campaignList[index] = obj;
    setState((prev) => ({
      ...prev,
      campaignList,
    }));
  };

  const handleGenerateImage = (idx) => {
    const campaignList = [...state.campaignList];
    const obj = campaignList[idx];
    setState((prev) => ({
      ...prev,
      isLoading: true,
    }));

    axios
      .post("/api/v1/sentence-generator/", {
        // theme: obj["theme"],
        location: obj["location"],
        target_demography: obj["target_demography"],
        call_to_customers: obj["call_to_customers"],
        platfrom: obj["platfrom"],
      })
      // .then((res) => res?.data?.data)
      // .then((data) => {
      //   return axios.post("/api/v1/image-generator/", {
      //     title: data?.map((each) => each.title),
      //     description: data?.map((each) => each.description),
      //   });
      // })
      .then((res) => {
        let data = res["data"]["data"];
        let item = {
          ...obj,
          imageGenerationData: data,
          block1: true,
        };
        campaignList[idx] = item;
        setState((prev) => ({
          ...prev,
          isLoading: false,
          campaignList,
        }));
      })
      .catch((e) => {
        setState((prev) => ({
          ...prev,
          isLoading: false,
        }));
      });
  };

  const handleCardItemClick = (obj, idx) => {
    console.log(obj.image);
    const selectedImage = [...state.selectedImage];

    let selectedImageObj = { ...state.selectedImageObj };

    if (selectedImage.includes(obj.image)) {
      let imageIndex = selectedImage.indexOf(obj.image);
      selectedImage.splice(imageIndex, 1);
      delete selectedImageObj[obj.image];

      setState((prev) => ({
        ...prev,
        selectedImage,
        selectedImageObj,
      }));
    } else {
      selectedImage.push(obj.image);
      selectedImageObj = {
        ...selectedImageObj,
        [obj.image]: obj,
      };

      setState((prev) => ({ ...prev, selectedImage, selectedImageObj }));
    }

    // selectedImage.push(obj);
    // setState((prev) => ({ ...prev, selectedImage }));
  };

  const handleGetMetric = () => {
    setState((prev) => ({
      ...prev,
      isLoading: true,
      showPlot: false,
    }));

    axios
      .post("/api/v1/mab-metric/", {
        data: Object.values(state.selectedImageObj),
      })
      .then((res) =>
        setState((prev) => ({
          ...prev,
          plotData: res.data?.metric,
          amountSaved: res.data["savings"],
          isLoading: false,
          showPlot: true,
        }))
      )
      .catch((e) => {
        setState((prev) => ({
          ...prev,
          isLoading: false,
        }));
      });
  };

  return (
    <>
      <CssBaseline />
      <AppBar enableColorOnDark position="fixed" color="inherit" elevation={0}>
        <Toolbar>
          <Header />
        </Toolbar>
      </AppBar>
      <div style={{ paddingTop: "100px", paddingBottom: "100px" }}>
        <Container maxWidth="lg">
          <Route path={["/", "/summary"]}>
            <Switch>
              <Route
                path={"/"}
                render={(props) => (
                  <ImageGeneration
                    handleAddCampaign={handleAddCampaign}
                    state={state}
                    handleInputChange={handleInputChange}
                    handleGenerateImage={handleGenerateImage}
                    handleCardItemClick={handleCardItemClick}
                    handleGetMetric={handleGetMetric}
                    {...props}
                  />
                )}
              />
              <Route path={"/summary"} component={Summary} />
              {/* <Route path={"/"} component={AddCampaign} /> */}
            </Switch>
          </Route>
        </Container>
      </div>
    </>
  );
};

export default InsuranceApp;
