import {
  Grid,
  Card,
  CardActionArea,
  CardActionAreaClassKey,
  CardContent,
  CardMedia,
  Typography,
  TableRow,
  TableCell,
  Table,
} from "@material-ui/core";
import React from "react";
import HorizontalBarChart from "../views/charts/HorizontalBarChart";
import PieChart from "../views/charts/PieChart";
import { makeStyles, useTheme } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
  cardRoot: {
    minWidth: "25%",
  },
  cardMedia: {
    height: 300,
    objectFit: "contain",
    display: "block",
    margin: "0 auto",
    "&:hover": {
      transform: "scale(1.5)",
    },
  },
  tableHead: {
    fontWeight: 600,
  },
}));

const MetricCards = ({ data, amountSaved }) => {
  const classes = useStyles();
  //   console.log(data);
  return (
    <Grid container spacing={3} sx={{ marginTop: 1 }}>
      <Grid
        item
        xs={4}
        display="flex"
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Card
          className={classes.root}
          sx={{
            border: "1px solid #e9e8e8",
            boxShadow: "0 3px 8px rgb(209 209 209 / 60%)",
            //borderColor: theme.palette.primary[200] + 75,
            // ":hover": {
            //   boxShadow: boxShadow
            //     ? shadow
            //       ? shadow
            //       : "0 2px 14px 0 rgb(32 40 45 / 8%)"
            //     : "inherit",
            // },
          }}
        >
          <CardContent>
            <Typography
              gutterBottom
              variant="h4"
              color="textSecondary"
              component="h2"
            >
              Savings compared to Default A/B Testing in the platform
            </Typography>
            <Typography
              gutterBottom
              variant="h1"
              color={"primary"}
              component="h1"
            >
              {amountSaved}$
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={8}>
        <HorizontalBarChart
          title={"Estimated Engagement Rate"}
          chartHeight={310}
          refId={"bar-chart"}
          data={data}
          x_column={"title"}
          y_column={"theta"}
        />
      </Grid>
      <Grid item xs={12}>
        <div
          style={{
            display: "flex",
            gap: "10px",
            flexWrap: "wrap",
          }}
        >
          {data?.map((each, index) => (
            <Card
              className={classes.root}
              key={index}
              sx={{
                border: "1px solid #e9e8e8",
                boxShadow: "0 3px 8px rgb(209 209 209 / 60%)",
                //borderColor: theme.palette.primary[200] + 75,
                // ":hover": {
                //   boxShadow: boxShadow
                //     ? shadow
                //       ? shadow
                //       : "0 2px 14px 0 rgb(32 40 45 / 8%)"
                //     : "inherit",
                // },
              }}
            >
              <CardActionArea>
                {/* <CardMedia className={classes.cardMedia} image={each?.image} /> */}
                <CardContent>
                  <img
                    src={window.location.href + each.image}
                    className={classes.media}
                  />
                  <Typography gutterBottom variant="h5" component="h2">
                    {each.title}
                  </Typography>
                  {/* <Typography variant="body1" component="p">
                    {each.prompt}
                  </Typography> */}
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    component="p"
                  >
                    {each.description}
                  </Typography>
                  <Table>
                    <TableRow>
                      <TableCell className={classes.tableHead}>
                        Amount spent ($)
                      </TableCell>
                      <TableCell>{each["Amount spent ($)"]}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.tableHead}>
                        Cost per click
                      </TableCell>
                      <TableCell>{each["Cost per click"]}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.tableHead}>
                        Impressions
                      </TableCell>
                      <TableCell>{each["Impressions"]}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={classes.tableHead}>
                        Engagement
                      </TableCell>
                      <TableCell>{each["Reach"]}</TableCell>
                    </TableRow>
                  </Table>
                </CardContent>
              </CardActionArea>
            </Card>
          ))}
        </div>
      </Grid>
    </Grid>
  );
};

export default MetricCards;
