import React from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/styles";
import {
  Card,
  CardActionArea,
  CardActionAreaClassKey,
  CardContent,
  CardMedia,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: {
    height: "100%",
    border: "1px solid #cccccc",
    position: "relative",
    "&.contentSelected": {
      borderColor: "#d2212f",
    },
    // "&:hover": {
    //   backgroundColor: "#f7f7f7",
    //   borderColor: "#ccc",
    // },
  },
  media: {
    height: 300,
    display: "block",
    margin: "0 auto",
    backgroundSize: "contain !important",
    cursor: "pointer",
    "&:hover": {
      opacity: 0.9,
      transform: "scale(1.1)",
    },
  },
  description: {
    color: "#333",
    lineHeight: "24px",
    cursor: "pointer",
    "&:hover": {
      opacity: 0.9,
      color: "#000",
      transform: "scale(1.01)",
      fontWeight: 500,
    },
  },
  overlayCheck: {
    position: "absolute",
    background: "rgba(255,255,255,0.9)",
    padding: "3px 12px 3px 6px",
    borderRadius: "10px",
    right: "12px",
    top: "12px",
    margin: 0,
    color: "#333333 !important",
    "& .MuiButtonBase-root": {
      padding: "3px",
    },
  },
}));
const ImageCard = ({ obj, handleCardItemClick, campaignIndex, state }) => {
  const classes = useStyles();

  const download = (url) => {
    fetch(url, {
      method: "GET",
      headers: {},
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "image.png"); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Card className={classes.root}>
      {/* <CardActionArea sx={{ color: "transparent !important" }}> */}
      <FormControlLabel
        className={classes.overlayCheck}
        control={
          <Checkbox
            onClick={() => handleCardItemClick(obj, campaignIndex)}
            checked={state?.selectedImage?.includes(obj.uuid)}
          />
        }
        label="Selected"
      />
      <CardMedia
        className={classes.media}
        image={window.location.origin + "/" + obj.image}
        // image={obj.image}
        onClick={() => download(window.location.origin + "/" + obj.image)}
        // onClick={() => download(obj.image)}
      />

      <CardContent
      //   className={
      //     state.selectedImage.includes(obj.image)
      //       ? classes.contentSelected
      //       : ""
      //   }
      >
        {/* <img
            // src={window.location.href + obj.image}
            src="https://mui.com/static/images/cards/contemplative-reptile.jpg"
            className={classes.media}
          /> */}
        {/* <Typography
            variant="h5"
            component="h2"
            sx={{ fontSize: "16px", lineHeight: "24px", marginBottom: "12px" }}
          >
            {obj.title}
          </Typography> */}
        <Typography
          onClick={() => {
            navigator.clipboard.writeText(obj.description);
          }}
          component="p"
          className={classes.description}
        >
          {obj.description}
        </Typography>
      </CardContent>
      {/* </CardActionArea> */}
    </Card>
  );
};

ImageCard.propTypes = {};

ImageCard.defaultProps = {
  obj: {
    uuid: 100000,
    prompt:
      "Write a creative ad for the following product to run on Instagram aimed at Young Professionals at Kings County, NY and based on following target customer interests:\n\nInterests: cars\n\nProduct: Bank of America credit card's rewards points provides 50% discount that caters to customer's interests. It can be used to buy 'Sicilian Pizza'. i want to save. \n\n\n",
    title:
      "Get 50% off your favorite cars with the bank of america credit card!",
    description:
      "Looking to save on your next pizza night? bank of america's credit card rewards points can get you 50% off at participating sicilian pizza locations. so whether you're grabbing a slice or a whole pie, you can enjoy some delicious savings.",
    location: "Kings County, NY",
    image:
      "https://i.picsum.photos/id/237/200/300.jpg?hmac=TmmQSbShHz9CdQm0NkEjx1Dyh_Y984R9LpNrpvH2D_U",
  },
  handleCardItemClick: console.log,
  campaignIndex: 111111,
  state: {},
};

export default ImageCard;
