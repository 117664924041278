import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";

// material-ui
import { makeStyles, withStyles } from "@material-ui/styles";
import {
  Box,
  Grid,
  Typography,
  CircularProgress,
  Tooltip,
} from "@material-ui/core";

// imports
import MainCard from "../MainCard";
import Transitions from "../Transitions";

import InfoIcon from "@material-ui/icons/InfoOutlined";

// Tooltip styles
const useStylesBootstrap = makeStyles((theme) => ({
  tooltip: {
    //   backgroundColor: theme.palette.common.black,
    fontSize: 14,
    fontWeight: 400,
    padding: 10,
  },
  overlayWrap: {
    position: "absolute",
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "4",
    background: "rgba(255, 255, 255, 0.7)",
    "& h5": {
      color: "#009b54",
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: "600",
    },
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

const CustomCardComponent = React.forwardRef(
  (
    {
      cardTitle,
      isTitleLocalize,
      showInfoIcon,
      infoIconContent,
      cardAction,
      contentHeight,
      isLoadingSpin,
      children,
      isCard,
      showNoDataText,
      isFeatureDisabled,
      t,
    },
    ref
  ) => {
    const classes = useStylesBootstrap();
    return (
      <>
        {isCard ? (
          <Transitions in={true}>
            <MainCard ref={ref} content={true}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                    flexWrap="wrap"
                    spacing={1}
                  >
                    <Grid item>
                      <Typography variant="h4">
                        {isTitleLocalize ? t(cardTitle) : cardTitle}
                      </Typography>
                    </Grid>
                    <Grid item display="flex" alignItems="center">
                      {cardAction}
                      {showInfoIcon && (
                        <BootstrapTooltip
                          title={infoIconContent}
                          sx={{ marginLeft: 1 }}
                        >
                          <InfoIcon />
                        </BootstrapTooltip>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Box height={contentHeight} width="100%">
                    {/* Overlay Feature */}
                    {isFeatureDisabled && (
                      <Box className={classes.overlayWrap}>
                        <Typography
                          variant={"h5"}
                          textAlign="center"
                          color="inherit"
                        >
                          This feature will be available with one month's data
                        </Typography>
                      </Box>
                    )}
                    {/* Loading Spin */}
                    {isLoadingSpin && (
                      <Box
                        display="flex"
                        height="100%"
                        width="100%"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <CircularProgress color="primary" />
                      </Box>
                    )}
                    {/* No Content Text */}
                    {!children && !isLoadingSpin && showNoDataText && (
                      <Typography
                        variant={"h5"}
                        textAlign="center"
                        color="inherit"
                        sx={{ fontWeight: 400, color: "#666666" }}
                      >
                        {t("NoDataText")}
                      </Typography>
                    )}
                    {/* card Content  */}
                    {!isLoadingSpin && children ? children : null}
                  </Box>
                </Grid>
              </Grid>
            </MainCard>
          </Transitions>
        ) : (
          <Box height={contentHeight} width="100%">
            {/* Loading Spin */}
            {isLoadingSpin && (
              <Box
                display="flex"
                height="100%"
                width="100%"
                justifyContent="center"
                alignItems="center"
              >
                <CircularProgress color="primary" />
              </Box>
            )}
            {/* No Content Text */}
            {!children && !isLoadingSpin && showNoDataText && (
              <Typography
                variant={"h5"}
                textAlign="center"
                color="inherit"
                sx={{ paddingTop: "10px", fontWeight: 500, color: "#808594" }}
              >
                {t("NoDataText")}
              </Typography>
            )}
            {/* card Content  */}
            {!isLoadingSpin && children ? children : null}
          </Box>
        )}
      </>
    );
  }
);

CustomCardComponent.propTypes = {
  isCard: PropTypes.bool,
  showNoDataText: PropTypes.bool,
  showInfoIcon: PropTypes.bool,
  infoIconContent: PropTypes.string,
  cardTitle: PropTypes.string,
  isTitleLocalize: PropTypes.bool,
  contentHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isLoadingSpin: PropTypes.bool.isRequired,
  children: PropTypes.node,
  cardAction: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.object,
  ]),
  isFeatureDisabled: PropTypes.bool,
};

CustomCardComponent.defaultProps = {
  isLoadingSpin: false,
  isTitleLocalize: false,
  isCard: true,
  showNoDataText: true,
  showInfoIcon: false,
  infoIconContent: "Sample Text",
  isFeatureDisabled: false,
};

export default withTranslation()(CustomCardComponent);
