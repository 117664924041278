import config from "../../../config";

const HBarChartData = {
  series: [],
  options: {
    chart: {
      id: "h-bar-chart",
      fontFamily: config.fontFamily,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        distributed: true,
        horizontal: true,
      },
    },
    grid: {
      show: true,
      strokeDashArray: 2,
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    yaxis: {
      labels: {
        show: false,
      },
      max: 1,
    },

    legend: {
      show: true,
      position: "top",
    },

    tooltip: {
      fixed: {
        enabled: false,
      },
      x: {
        show: false,
      },
      marker: {
        show: false,
      },
      y: {
        title: {
          formatter: function (val, opts) {
            return opts.w.globals.labels[opts.dataPointIndex];
          },
        },
        formatter: (value) => {
          return null;
        },
      },
    },
  },
};

export default HBarChartData;
