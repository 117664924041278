import React, { useState, useEffect } from "react";
import {
  Route,
  useLocation,
  Redirect,
  Switch,
  withRouter,
} from "react-router-dom";
import {
  Container,
  AppBar,
  Toolbar,
  CssBaseline,
  Typography,
  TextField,
  Button,
  Grid,
  Autocomplete,
} from "@material-ui/core";
import axios from "axios";
import config from "../config";
import GenerateCreative from "./GenerateCreative";
import CreativeOutput from "./CreativeOutput";
import TestCampaigns from "./TestCampaigns";
import Header from "./Header";

import CreativeOutputData from "../mock-adapter/TableTop/GenerateImage.json";
import HorizontalBarChart from "../views/charts/HorizontalBarChart";

const campaignObj = {
  interest: "",
  location: "",
  target_demography: "",
  call_to_action: "",
  platfrom: "",
  call_to_customers: "",
  imageGenerationData: [],
  block1: false,
};
const TableTopApp = ({ history }) => {
  const location = useLocation();

  const menuItems = [
    {
      title: "Generate Creatives",
      url: "/generate-creative",
      id: "gc",
      index: 0,
    },
    {
      title: "Creatives Output",
      url: "/creative-output",
      id: "co",
      index: 1,
    },
    {
      title: "Test Campaigns",
      url: "/test-campaigns",
      id: "tc",
      index: 2,
    },
  ];

  const [state, setState] = useState({
    currentCampaign: 0,
    isLoading: false,
    optionsData: {},
    campaignList: [],
    campaignObj,

    selectedImage: [],
    selectedImageObj: {},
    plotData: [],
    amountSaved: "",
    showPlot: false,
  });

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      isLoading: true,
    }));
    axios
      .get("/api/v1/get-audience-and-platform/")
      .then((res) => {
        setState((prev) => ({
          ...prev,
          optionsData: res.data,
          isLoading: false,
        }));

        history.push("/generate-creative");
      })
      .catch((e) => {
        setState((prev) => ({
          ...prev,
          isLoading: false,
        }));
      });
  }, []);

  const handleInputChange = (id, value) => {
    let campaignObj = { ...state.campaignObj };

    campaignObj = {
      ...campaignObj,
      [id]: value,
    };

    setState((prev) => ({
      ...prev,
      campaignObj,
    }));
  };

  const handleGenerateImage = () => {
    const obj = state.campaignObj;
    let currentCampaign = state.currentCampaign;
    setState((prev) => ({
      ...prev,
      isLoading: true,
    }));

    axios
      .post("/api/v1/sentence-generator/", {
        interest: obj["interest"],
        location: obj["location"],
        target_demography: obj["target_demography"],
        call_to_customers: obj["call_to_customers"],
        platfrom: obj["platfrom"],
      })
      // .then((res) => res?.data?.data)
      // .then((data) => {
      //   return axios.post("/api/v1/image-generator/", {
      //     title: data?.map((each) => each.title),
      //     description: data?.map((each) => each.description),
      //   });
      // })
      .then((res) => {
        const campaignList = state.campaignList;
        if (res["data"]["status"]) {
          let data = res["data"]["data"];
          console.log(data);
          let item = {
            ...obj,
            imageGenerationData: data,
            block1: true,
          };
          campaignList[currentCampaign] = item;
          setState((prev) => ({
            ...prev,
            campaignList,
          }));
          history.push("/creative-output");
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setState((prev) => ({
          ...prev,
          isLoading: false,
        }));
      });
  };

  const handleCardItemClick = (obj, idx) => {
    console.log(obj.uuid);
    const selectedImage = [...state.selectedImage];

    let selectedImageObj = { ...state.selectedImageObj };

    if (selectedImage.includes(obj.uuid)) {
      let imageIndex = selectedImage.indexOf(obj.uuid);
      selectedImage.splice(imageIndex, 1);
      delete selectedImageObj[obj.uuid];

      setState((prev) => ({
        ...prev,
        selectedImage,
        selectedImageObj,
      }));
    } else {
      selectedImage.push(obj.uuid);
      selectedImageObj = {
        ...selectedImageObj,
        [obj.uuid]: obj,
      };

      setState((prev) => ({ ...prev, selectedImage, selectedImageObj }));
    }

    // selectedImage.push(obj);
    // setState((prev) => ({ ...prev, selectedImage }));
  };

  const handleGetMetric = () => {
    setState((prev) => ({
      ...prev,
      isLoading: true,
      showPlot: false,
    }));

    axios
      .post("/api/v1/mab-metric/", {
        data: Object.values(state.selectedImageObj),
      })
      .then((res) => {
        setState((prev) => ({
          ...prev,
          plotData: res.data?.metric,
          amountSaved: res.data["savings"],
          isLoading: false,
          showPlot: true,
        }));

        history.push("/test-campaigns");
      })
      .catch((e) => {
        setState((prev) => ({
          ...prev,
          isLoading: false,
        }));
      });
  };

  const handleCreateNewCreative = () => {
    setState((prev) => ({
      ...prev,
      campaignObj,
      currentCampaign: prev.currentCampaign++,
    }));
    history.push("/generate-creative");
  };

  return (
    <>
      <CssBaseline />
      <AppBar
        enableColorOnDark
        position="static"
        color="inherit"
        elevation={0}
        sx={{ backgroundColor: "#f5f5f5" }}
      >
        <Toolbar>
          <Header navItems={menuItems} />
        </Toolbar>
      </AppBar>
      <div style={{ paddingTop: "70px", paddingBottom: "50px" }}>
        <Container maxWidth="lg">
          <Route
            path={[
              "/",
              "/generate-creative",
              "/creative-output",
              "/test-campaigns",
            ]}
          >
            <Switch>
              <Redirect exact from="/" to={config.defaultPath} />
              <Route
                path={"/generate-creative"}
                render={(props) => (
                  <GenerateCreative
                    state={state}
                    handleInputChange={handleInputChange}
                    handleGenerateImage={handleGenerateImage}
                    {...props}
                  />
                )}
              />

              <Route
                path={"/creative-output"}
                render={(props) => (
                  <CreativeOutput
                    state={state}
                    handleGetMetric={handleGetMetric}
                    handleCardItemClick={handleCardItemClick}
                    handleCreateNewCreative={handleCreateNewCreative}
                    {...props}
                  />
                )}
              />
              <Route
                path={"/test-campaigns"}
                render={(props) => <TestCampaigns state={state} {...props} />}
              />
            </Switch>
          </Route>
        </Container>
      </div>
    </>
  );
};

export default withRouter(TableTopApp);
