import React from "react";
import PropTypes from "prop-types";
import {
  Container,
  AppBar,
  Toolbar,
  CssBaseline,
  Typography,
  TextField,
  Button,
  Grid,
  Autocomplete,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/styles";
import ImgCard from "./ImgCard";

const useStyles = makeStyles(() => ({
  boxWrapper: {
    border: "1px solid lightgrey",
    borderRadius: "5px",
    padding: "20px",
    marginBottom: "30px",
  },
  inputField: {
    marginTop: "10px",
    marginBottom: "30px",
  },
}));

let imageData = [
  {
    prompt:
      "Write a creative ad for the following product to run on Facebook for College Students:\n\n Get delicious Large Cheese Pizza from nearby Restaurant by using 'Fwells Fargo Credit Card Points' in Cayuga County, NY. Contact Us.",
    title: "Looking for a delicious pizza near you? look no further than f",
    description:
      "Looking for a delicious, large cheese pizza from nearby restaurants? Look no further than Fwells Fargo Credit Card Points! We offer great food and great service in Cayuga County, NY. Our pizza is perfect for any occasion and we offer delivery and hand delivery for home delivery. Plus, we offer loyalty cards andpoints for just $5point. So if you're ever in the area, come on over to Fwells Fargo Credit Card Points!",
    location: "Cayuga County, NY",
    image: "media/images/temp_4id03k8.png",
  },
  {
    prompt:
      "Write a creative ad for the following product to run on Facebook for College Students:\n\n Get delicious Large Cheese Pizza from nearby Restaurant by using 'Fwells Fargo Credit Card Points' in Cayuga County, NY. Contact Us.",
    title: "Looking for delicious pizza in cayuga county, ny? fwell",
    description:
      "Looking for a delicious pizza near you? Look no further than Fwells Fargo Credit Card Points! We offer delicious large cheese pizzas at our nearby restaurant. Whether you're looking for the best pizza in Fargo or a new experience, we've got you covered. Plus, our delicious pizza is perfect for those who are looking to break the bank. So come on over and try our delicious pizza today!",
    location: "Cayuga County, NY",
    image: "media/images/temp_QtFxv1Y.png",
  },
  {
    prompt:
      "Write a creative ad for the following product to run on Facebook for College Students:\n\n Get delicious Large Cheese Pizza from nearby Restaurant by using 'Fwells Fargo Credit Card Points' in Cayuga County, NY. Contact Us.",
    title:
      "Our large cheese pizza is perfect for those who love nothing more than",
    description:
      "Looking for a delicious, large cheese pizza from nearby Cayuga County, NY? Contact us today! We offer points for books in Cayuga County, NY.",
    location: "Cayuga County, NY",
    image: "media/images/temp_aMXKfJN.png",
  },
];

const CampaignBox = ({
  campaignObj,
  campaignIndex,
  state,
  handleInputChange,
  handleGenerateImage,
  handleCardItemClick,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.boxWrapper}>
      <Typography sx={{ marginBottom: "15px" }} variant="h4">
        Campaign {campaignIndex + 1}:
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {/* <Typography variant="h5">Theme</Typography>
          <Autocomplete
            className={classes.inputField}
            id="theme"
            options={state.optionsData["theme"]}
            value={campaignObj["theme"]}
            // onChange={(e, newValue) =>
            //   handleInputChange("theme", newValue, campaignIndex)
            // }
            renderInput={(params) => (
              <TextField {...params} color="secondary" placeholder="Search" />
            )}
          /> */}

          <Typography variant="h5">Location</Typography>
          <Autocomplete
            // multiple
            className={classes.inputField}
            id="location"
            options={state.optionsData["location"]}
            value={campaignObj["location"]}
            onChange={(e, newValue) =>
              handleInputChange("location", newValue, campaignIndex)
            }
            renderInput={(params) => (
              <TextField {...params} color="secondary" placeholder="Search" />
            )}
          />

          <Typography variant="h5">Target Demography</Typography>
          <Autocomplete
            className={classes.inputField}
            id="target_demography"
            options={state.optionsData["target_demography"]}
            value={campaignObj["target_demography"]}
            onChange={(e, newValue) =>
              handleInputChange("target_demography", newValue, campaignIndex)
            }
            renderInput={(params) => (
              <TextField {...params} color="secondary" placeholder="Search" />
            )}
          />

          {/* <Typography variant="h5">Call To Action</Typography>
          <Autocomplete
            className={classes.inputField}
            id="call_to_action"
            options={state.optionsData["call_to_action"]}
            value={campaignObj["call_to_action"]}
            onChange={(e, newValue) =>
              handleInputChange("call_to_action", newValue, campaignIndex)
            }
            renderInput={(params) => (
              <TextField {...params} color="secondary" placeholder="Search" />
            )}
          /> */}

          <Typography variant="h5">Platfrom</Typography>
          <Autocomplete
            className={classes.inputField}
            id="Platfrom"
            options={state.optionsData["platfrom"]}
            value={campaignObj["platfrom"]}
            onChange={(e, newValue) =>
              handleInputChange("platfrom", newValue, campaignIndex)
            }
            renderInput={(params) => (
              <TextField {...params} color="secondary" placeholder="Search" />
            )}
          />

          <Typography variant="h5">Call to customers</Typography>
          {/* <Autocomplete
            className={classes.inputField}
            id="Platfrom"
            options={state.optionsData["platfrom"]}
            value={campaignObj["platfrom"]}
            onChange={(e, newValue) =>
              handleInputChange("platfrom", newValue, campaignIndex)
            }
            renderInput={(params) => (
              <TextField {...params} color="secondary" placeholder="Search" />
            )}
          /> */}

          <TextField
            fullWidth
            className={classes.inputField}
            id="call_to_customers"
            value={campaignObj["call_to_customers"]}
            onChange={(e) =>
              handleInputChange(
                "call_to_customers",
                e.target.value,
                campaignIndex
              )
            }
          />

          <Button
            sx={{ alignContent: "center" }}
            variant="contained"
            onClick={() => handleGenerateImage(campaignIndex)}
          >
            Generate
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            {campaignObj?.block1 && campaignObj.imageGenerationData?.length > 0
              ? campaignObj.imageGenerationData.map((obj, i) => (
                  <Grid key={i} item xs={6}>
                    <ImgCard
                      obj={obj}
                      state={state}
                      campaignIndex={campaignIndex}
                      handleCardItemClick={handleCardItemClick}
                    />
                  </Grid>
                ))
              : null}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

CampaignBox.propTypes = {};

export default CampaignBox;
