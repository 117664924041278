import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import config from "../../config";

// material-ui
import { makeStyles } from "@material-ui/styles";
import {
  Container,
  Box,
  Button,
  ButtonBase,
  Stepper,
  Step,
  StepButton,
  StepLabel,
} from "@material-ui/core";

// style constant
const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  headerAvatar: {
    ...theme.typography.commonAvatar,
    ...theme.typography.mediumAvatar,
    transition: "all .2s ease-in-out",
    background: theme.palette.secondary.light,
    color: theme.palette.secondary.dark,
    "&:hover": {
      background: theme.palette.secondary.dark,
      color: theme.palette.secondary.light,
    },
  },
  boxContainer: {
    width: "228px",
    display: "flex",
    [theme.breakpoints.down("md")]: {
      width: "auto",
    },
  },
  customStepper: {
    minWidth: "660px",
    "& .MuiStepLabel-label": {
      fontWeight: 500,
      fontSize: "15px",
    },
  },
}));

//-----------------------|| MAIN NAVBAR / HEADER ||-----------------------//

const Header = ({ navItems }) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  console.log("location", location);

  const currentMenuArray = navItems.filter((item) => item.url === pathname);
  console.log("currentMenuArray", currentMenuArray);

  return (
    <>
      <Container maxWidth="lg">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <ButtonBase disableRipple component={Link} to={config.defaultPath}>
            <img
              src={"/static/img/ongil-2x.png"}
              alt="logo"
              style={{ width: "215px" }}
            />
          </ButtonBase>
          <Box>
            <Stepper
              activeStep={
                currentMenuArray.length ? currentMenuArray[0].index : 0
              }
              className={classes.customStepper}
            >
              {navItems.map((item, index) => (
                <Step key={item.title}>
                  {/* <StepLabel>{item.title}</StepLabel> */}
                  <StepLabel color="inherit">{item.title}</StepLabel>
                </Step>
              ))}
            </Stepper>

            {/* {navItems.map((item, index) => (
              <Button
                key={index}
                sx={{ ml: 3, borderRadius: "12px" }}
                color="primary"
                size="large"
                variant={activeMenu === item.id ? "contained" : "outlined"}
                onClick={() => handleMenuClick(item)}
              >
                {item.title}
              </Button>
            ))} */}
          </Box>
        </Box>
      </Container>
    </>
  );
};

Header.propTypes = {
  handleMenuClick: PropTypes.func,
  activeMenu: PropTypes.string,
  navItems: PropTypes.array,
};

export default Header;
