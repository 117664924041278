import { Button, Typography, Box } from "@material-ui/core";
import React from "react";
import AddIcon from "@material-ui/icons/Add";
import CampaignBox from "./CampaignBox";
import MetricCards from "./MetricCards";

const ImageGeneration = ({
  handleAddCampaign,
  state,
  handleGetMetric,
  ...rest
}) => {
  console.log(state?.plotData);
  return (
    <div>
      {state?.campaignList?.map((eachCampaign, campaignIndex) => (
        <CampaignBox
          key={campaignIndex}
          campaignObj={eachCampaign}
          campaignIndex={campaignIndex}
          state={state}
          {...rest}
        />
      ))}
      {state.selectedImage.length > 0 && (
        <Box style={{ display: "flex", justifyContent: "center" }}>
          <Button onClick={handleGetMetric} variant="contained">
            Submit
          </Button>
        </Box>
      )}

      <Button
        onClick={handleAddCampaign}
        startIcon={<AddIcon />}
        variant="contained"
      >
        Add Campaign
      </Button>

      {state.showPlot && (
        <MetricCards data={state?.plotData} amountSaved={state?.amountSaved} />
      )}
      {state.isLoading && <Typography variant="h2">Loading...</Typography>}
    </div>
  );
};

export default ImageGeneration;
