import React from "react";
import { makeStyles, useTheme } from "@material-ui/styles";
import {
  Card,
  CardActionArea,
  CardActionAreaClassKey,
  CardContent,
  CardMedia,
  Typography,
} from "@material-ui/core";
import { Block } from "@material-ui/icons";
import { autoType } from "d3";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    margin: 1,
  },
  media: {
    height: 300,
    objectFit: "contain",
    display: "block",
    margin: "0 auto",
    "&:hover": {
      transform: "scale(1.5)",
    },
  },
  contentSelected: {
    background: "lightgrey",
  },
}));
const ImgCard = ({ obj, handleCardItemClick, campaignIndex, state }) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardActionArea onClick={() => handleCardItemClick(obj, campaignIndex)}>
        {/* <CardMedia className={classes.media} image={obj.image} />/ */}
        <CardContent
          className={
            state.selectedImage.includes(obj.image)
              ? classes.contentSelected
              : ""
          }
        >
          <img
            src={window.location.href + obj.image}
            className={classes.media}
          />
          <Typography gutterBottom variant="h5" component="h2">
            {obj.title}
          </Typography>
          {/* <Typography variant="body1"  component="p">
            {obj.prompt}
          </Typography> */}
          <Typography variant="body1" color="textSecondary" component="p">
            {obj.description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default ImgCard;
