import React from "react";
import Page from "../../components/Page";
import {
  Typography,
  Box,
  Grid,
  Autocomplete,
  TextField,
  Button,
  CircularProgress,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/styles";
import { validateArrayData } from "../../utils";
const useStyles = makeStyles(() => ({
  boxWrapper: {
    border: "1px solid lightgrey",
    borderRadius: "5px",
    padding: "20px",
    marginBottom: "30px",
  },
  inputField: {
    marginTop: "5px",
  },
}));

const GenerateCreative = ({
  state,
  handleInputChange,
  handleGenerateImage,
  ...others
}) => {
  const classes = useStyles();
  return (
    <Page title="Generate Creatives">
      {/* <Typography
        sx={{ fontSize: "24px", fontWeight: 500, marginBottom: "30px" }}
        variant="h3"
        textAlign="center"
      >
        Add Creative
      </Typography> */}
      <Box sx={{ maxWidth: "500px", margin: "0 auto" }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={12}>
            <Typography variant="h4">Location *</Typography>
            <Autocomplete
              // multiple
              className={classes.inputField}
              id="location"
              options={
                validateArrayData(state.optionsData["location"])
                  ? state.optionsData["location"]
                  : []
              }
              value={state.campaignObj["location"]}
              onChange={(e, newValue) =>
                handleInputChange("location", newValue)
              }
              renderInput={(params) => (
                <TextField {...params} color="secondary" placeholder="Search" />
              )}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography variant="h4">Target Demography *</Typography>
            <Autocomplete
              className={classes.inputField}
              id="target_demography"
              options={
                validateArrayData(state.optionsData["target_demography"])
                  ? state.optionsData["target_demography"]
                  : []
              }
              value={state.campaignObj["target_demography"]}
              onChange={(e, newValue) =>
                handleInputChange("target_demography", newValue)
              }
              renderInput={(params) => (
                <TextField {...params} color="secondary" placeholder="Search" />
              )}
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <Typography variant="h4">Interest *</Typography>
            <TextField
              fullWidth
              maxRows={4}
              multiline
              className={classes.inputField}
              id="interest"
              value={state.campaignObj["interest"]}
              onChange={(e) => handleInputChange("interest", e.target.value)}
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <Typography variant="h4">Call To Customers *</Typography>
            <TextField
              fullWidth
              maxRows={4}
              multiline
              className={classes.inputField}
              id="call_to_customers"
              value={state.campaignObj["call_to_customers"]}
              onChange={(e) =>
                handleInputChange("call_to_customers", e.target.value)
              }
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <Typography variant="h4">Platform *</Typography>
            <Autocomplete
              className={classes.inputField}
              id="Platfrom"
              options={
                validateArrayData(state.optionsData["platfrom"])
                  ? state.optionsData["platfrom"]
                  : []
              }
              value={state.campaignObj["platfrom"]}
              onChange={(e, newValue) =>
                handleInputChange("platfrom", newValue)
              }
              renderInput={(params) => (
                <TextField {...params} color="secondary" placeholder="Search" />
              )}
            />
          </Grid>

          <Grid item xs={12} textAlign="center">
            <Button
              variant="contained"
              color="secondary"
              size="large"
              onClick={handleGenerateImage}
              className={state.isLoading ? "btnLoading" : ""}
              // style={{
              //   boxShadow: "0 0 6px rgba(0,0,0,0.5) ",
              // }}
            >
              Generate
              <CircularProgress />
            </Button>
          </Grid>

          {/* <Grid item xs={12}>
            <Grid container spacing={2}>
              {campaignObj?.block1 &&
              campaignObj.imageGenerationData?.length > 0
                ? campaignObj.imageGenerationData.map((obj, i) => (
                    <Grid key={i} item xs={6}>
                      <ImgCard
                        obj={obj}
                        state={state}
                        campaignIndex={campaignIndex}
                        handleCardItemClick={handleCardItemClick}
                      />
                    </Grid>
                  ))
                : null}
            </Grid>
          </Grid> */}
        </Grid>
      </Box>
    </Page>
  );
};

export default GenerateCreative;
