import React, { useEffect } from "react";
import PropTypes from "prop-types";
import config from "../../../config";

import { Box, CircularProgress, Typography } from "@material-ui/core";

//Apexcharts
import ApexCharts from "apexcharts";
import Chart from "react-apexcharts";
import HBarChartData from "./HBarChartData";
import CustomCardComponent from "../../../components/CustomCardComponent";

const HorizontalBarChart = ({
  data,
  isLoading,
  chartHeight,
  title,
  colors,
  x_column,
  y_column,
  infoIconContent,
  convertToChartData,
  customToolTipFunction,
}) => {
  useEffect(() => {
    const newChartData = {
      ...HBarChartData.options,
      colors,
      tooltip: {
        theme: "light",
        followCursor: false,
        // custom: customToolTipFunction,
      },
      // dataLabels: {
      //   enabled: true,
      //   textAnchor: "middle",
      //   style: {
      //     colors: ["#343a40"],
      //     fontFamily: config.fontFamily,
      //     fontWeight: 500,
      //     fontSize: 15,
      //   },
      //   // formatter: function (val, opt) {
      //   //   // return opt.w.globals.labels[opt.dataPointIndex]?.slice(0, 20) + "...";

      //   // },
      //   offsetX: 0,
      // },
      xaxis: {
        labels: {
          show: true,
        },
        // title: {
        //   text: "Revenue",
        //   style: {
        //     fontSize: "12px",
        //     fontFamily: config.fontFamily,
        //     fontWeight: 400,
        //     color: "#9e9e9e",
        //   },
        // },
      },
    };

    if (Array.isArray(data) && data.length) {
      ApexCharts.exec(
        `h-bar-chart`,
        "updateOptions",
        newChartData,
        false,
        true
      );

      ApexCharts.exec(
        `h-bar-chart`,
        "updateSeries",
        convertToChartData(data, x_column, y_column),
        true
      );
    }
  }, [data]);

  return (
    <CustomCardComponent
      cardTitle={title}
      contentHeight={chartHeight}
      isLoadingSpin={isLoading}
      infoIconContent={infoIconContent}
    >
      {Array.isArray(data) && data.length > 0 ? (
        <Chart {...HBarChartData} type="bar" height={chartHeight} />
      ) : null}
    </CustomCardComponent>
  );
};

HorizontalBarChart.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  chartHeight: PropTypes.number,
  colors: PropTypes.array,
  title: PropTypes.string,
  x_column: PropTypes.string,
  y_column: PropTypes.string,
  convertToChartData: PropTypes.func,
  customToolTipFunction: PropTypes.func,
};

HorizontalBarChart.defaultProps = {
  data: [
    {
      brand:
        "that you deserve the best of the best, and with our wells fargo loyalty card, you can get just that.",
      market_share: 0.3,
    },
    {
      brand: "Brand 2",
      market_share: 0.5,
    },
    {
      brand: "Brand 3",
      market_share: 0.9,
    },
    {
      brand: "Brand 4",
      market_share: 0.4,
    },
  ],
  isLoading: false,
  chartHeight: 300,
  colors: ["#ed4319", "#f4a347", "#a3c388", "#1a99aa", "#103c46"],
  title: "Sample text",
  x_column: "brand",
  y_column: "market_share",
  infoIconContent: "Coming soon...",
  convertToChartData: (data, x_column, y_column) => {
    let series = [];
    series.push({
      name: "Marketshare",
      data: data.map((value) => ({
        x: value[x_column],
        y: value[y_column],
      })),
    });
    return series;
  },
  customToolTipFunction: function ({ series, seriesIndex, dataPointIndex, w }) {
    return (
      '<div class="tooltip-apex">' +
      "<p class='tool-tip-title'>" +
      (w.config.series[0].data[dataPointIndex] &&
      w.config.series[0].data[dataPointIndex].x
        ? w.config.series[0].data[dataPointIndex].x
        : "") +
      "</p><br/>" +
      (w.config.series[0].data[dataPointIndex] &&
      w.config.series[0].data[dataPointIndex].y
        ? w.config.series[0].data[dataPointIndex].y
        : "") +
      "</div>"
    );
  },
};

export default HorizontalBarChart;
