import React from "react";
import { Table, TableRow, TableCell, Box, Card } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
const useStyles = makeStyles(() => ({
  overlayCard: {
    position: "relative",
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 5,
    backgroundColor: "rgba(255, 255, 255, 0.25)",
    padding: "20px",
    display: "flex",
    alignItems: "center",
    "& table": {
      border: "1px solid #c9c9c9",
      maxWidth: "350px",
      margin: "0 auto",
      background: "rgba(255, 255, 255, 0.8)",
      borderRadius: "4px",
      overflow: "hidden",
    },
    "& td": {
      fontSize: "16px",
      fontWeight: 600,
      borderColor: "#c9c9c9",
    },
  },
}));

const ImageOverlayCard = ({ obj }) => {
  const classes = useStyles();
  return (
    <Card className={classes.overlayCard}>
      <img
        src={window.location.origin + "/" + obj.image}
        className={classes.media}
        style={{
          display: "block",
          maxWidth: "100%",
          width: "100%",
          minHeight: "200px",
        }}
      />
      {/* <Typography gutterBottom variant="h5" component="h2">
          looking for a natural way to add some color to your hair? try indica
          blue hair dye!
        </Typography>
        <Typography variant="body1" color="textSecondary" component="p">
          looking for a natural way to add some color to your hair? try indica
          blue hair dye!
        </Typography> */}
      <Box className={classes.overlay}>
        <Table>
          <TableRow>
            <TableCell className={classes.tableHead}>
              Amount spent ($)
            </TableCell>
            <TableCell>{obj["Amount spent ($)"]}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.tableHead}>Cost per click</TableCell>
            <TableCell>{obj["Cost per click"]}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.tableHead}>Impressions</TableCell>
            <TableCell>{obj["Impressions"]}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.tableHead}>Engagement</TableCell>
            <TableCell>{obj["Reach"]}</TableCell>
          </TableRow>
        </Table>
      </Box>
    </Card>
  );
};

export default ImageOverlayCard;
