import React from "react";
import ImageCard from "./ImageCard";
import { Link, useHistory } from "react-router-dom";

import { Grid, Box, Button, Divider, Typography } from "@material-ui/core";
import Page from "../../components/Page";

const CreativeOutput = ({
  state,
  handleCardItemClick,
  handleGetMetric,
  handleCreateNewCreative,
}) => {
  const history = useHistory();
  return (
    <Page title={"Creative Output"}>
      <Box sx={{ marginBottom: "15px", marginTop: "-20px" }}>
        <Button
          sx={{ padding: "3px 20px", minWidth: "80px" }}
          variant="contained"
          color="secondary"
          size="small"
          onClick={() => history.push("/generate-creative")}
        >
          Back
        </Button>
      </Box>
      <Grid container spacing={4}>
        {state.campaignList?.map((eachCampaign, campaignIndex) => (
          <Grid item xs={12}>
            <Typography
              sx={{ fontSize: "24px", fontWeight: 500, marginBottom: "30px" }}
              variant="h3"
              textAlign="center"
            >
              {`Creative no: ${campaignIndex + 1}`}
            </Typography>
            <Grid container spacing={2}>
              {eachCampaign["imageGenerationData"]?.map((obj, i) => (
                <Grid key={i} item xs={6}>
                  <ImageCard
                    key={i}
                    obj={obj}
                    state={state}
                    campaignIndex={campaignIndex}
                    eachCampaign={eachCampaign}
                    handleCardItemClick={handleCardItemClick}
                  />
                </Grid>
              ))}
            </Grid>{" "}
            <Box padding={2}>
              <Divider />
            </Box>
          </Grid>
        ))}
        <Grid item xs={12}>
          <Box textAlign="center">
            <Button
              variant="contained"
              color="secondary"
              size="large"
              onClick={handleCreateNewCreative}
            >
              Add Another Creative
            </Button>
            <Button
              sx={{ ml: 2 }}
              variant="contained"
              color="primary"
              size="large"
              onClick={handleGetMetric}
            >
              Submit
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Page>
  );
};

export default CreativeOutput;
